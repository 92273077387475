import {FETCH_POST,LOGIN_USER, LOGOUT_USER} from '../actions/index';

//const INITIAL_STATE = {all: [], post: null};


export default function(state = {}, action)  {
  switch(action.type) {
    case FETCH_POST:
      return {...state, [action.payload.data.id]: action.payload.data};    
    case LOGIN_USER:
      return {...state, userData: action.payload.data}
    case LOGOUT_USER:
      return {...state, userData: null, pupilsData:null}
    default:
      return state;
  }
}
