import axios from 'axios';

export const FETCH_POST = 'fetch_post';
export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';


export function loginUser (values){
  
  const request =  axios.post('/api/benutzer/check', values)
  return {
    type: LOGIN_USER,
    payload: request
  };
}




export function logoutUser (){

  const sessionUser={
    userID:null
  }
  const request =  axios.post('/api/session', sessionUser)
  return {
    type: LOGOUT_USER,
    payload: request
  };
}


export function fetchPost(tag, id) {
  const request = axios.get(`/api/${tag}/${id}`);
    //'/api/' + tag + '/' + id);

  return{
    type: FETCH_POST,
    payload: request
  }

}

