import React from 'react';
import ReactDOM from 'react-dom/client';  
import Hauptseite from './components/hauptseite.jsx'
import reportWebVitals from './reportWebVitals';
import { Provider} from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise';
import reducers from './reducers';
import Router from 'react-router/lib/Router';
import browserHistory from 'react-router/lib/browserHistory';
import routes from './routes';



const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(
  promise
))(createStore);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={createStoreWithMiddleware(reducers)}>
          <Hauptseite />
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
